import {
  ContactWebsiteSocialMediaType,
  Country,
  PbeDocumentType,
  WorkStatus,
} from "./domain/api"
import {
  ActionProps,
  ActionsEnum,
  MenuModel,
  StatusProps,
} from "./models/models"
import { ScamStatus } from "./pages/Works/models"

export const ERRORS_API = [
  {
    needToShowMessageToToast: false,
    label: "Votre identifiant et/ou votre mot de passe n’ont pas été reconnus.",
    value: "UnrecognizedAccountError",
  },
  {
    needToShowMessageToToast: false,
    label:
      "Votre compte est actuellement verrouillé. Veuillez réessayer dans une demi-heure.",
    value: "LockedAccountError",
  },
  {
    needToShowMessageToToast: false,
    label:
      "Le compte associé à cet identifiant a été désactivé. Vous pouvez contacter le Pôle relations auteurices au 01 56 69 64 22 ou relations.auteurices@scam.fr",
    value: "OtherAccountError",
  },
  {
    needToShowMessageToToast: false,
    label: "Une erreur a été rencontrée lors de la création de votre compte.",
    value: "CreateAccountError",
  },
  {
    needToShowMessageToToast: false,
    label: "PalFormId est vide",
    value: "PalFormIdNotDefined",
  },
  {
    needToShowMessageToToast: false,
    label: "Ce format de fichier n'est pas autorisé.",
    value: "UploadedFileRejected",
  },
  {
    needToShowMessageToToast: false,
    label:
      "Votre document est momentanément indisponible. Veuillez réessayer plus tard.",
    value: "FileIsMissing",
  },
  {
    needToShowMessageToToast: false,
    label:
      "En tant que mandant vous n’avez pas accès à l’Espace connecté de la Scam.",
    value: "UserNotFound",
  },
  {
    needToShowMessageToToast: false,
    label:
      "Récupération du mot de passe impossible. Votre identifiant n'a pas été reconnu",
    value: "UnrecognizedLoginError",
  },
  {
    needToShowMessageToToast: true,
    label: "PAL déjà en cours de signature",
    value: "SigningEnvelopeSigningInProgress",
  },
  {
    needToShowMessageToToast: true,
    label: "PAL signée en cours de traitement",
    value: "SigningEnvelopeAlreadySigned",
  },
  {
    needToShowMessageToToast: true,
    label: "PAL déjà intégrée",
    value: "SigningPalAlreadyIntegrated",
  },
  {
    needToShowMessageToToast: true,
    label: "Le fichier sélectionné est vide.",
    value: "UploadedFileEmpty",
  },
]

export const FOOTER_NAVIGATION = [
  {
    href: "https://www.scam.fr/",
    title: "La Scam",
  },
  {
    href: "https://www.scam.fr/vous-creez-des-oeuvres/accedez-a-lespace-agnes-varda/",
    title: "Espace Agnès Varda",
  },
  {
    href: "https://www.scam.fr/actualites-ressources/",
    title: "Agenda",
  },
  {
    href: "/declarer-oeuvre",
    title: "Déclarer vos œuvres",
  },
  // {
  //   title: "Prix de la Scam",
  // },
  // {
  //   title: "Étoiles de la Scam",
  // },
  {
    href: "https://www.scam.fr/lessentiel/principes-juridiques/",
    title: "Repères juridiques",
  },
  // {
  //   title: "Espace membre",
  // },
  {
    href: " https://www.scam.fr/rejoignez-nos-equipes/",
    title: "Recrutements",
  },
  {
    href: "https://www.scam.fr/vous-creez-des-oeuvres/sachez-le/les-modeles-de-contrats/",
    title: "Modèles de contrats",
  },
  // {
  //   title: "Appel à candidatures",
  // },
  {
    href: "https://www.scam.fr/presse-logos/",
    title: "Communiqués",
  },
  {
    href: "https://www.scam.fr/vous-creez-des-oeuvres/sachez-le/fiches-juridiques/",
    title: "Fiches juridiques",
  },
  // {
  //   title: "Vidéothèque",
  // },
  {
    href: "https://www.scam.fr/lessentiel/prix-et-etoiles/",
    title: "Prix et Étoiles de la Scam",
  },
]
export const FOOTER_SUBFOOTER = [
  {
    title: "Mentions légales",
    href: "https://www.scam.fr/mentions-legales/",
  },
  {
    title: "Données personnelles",
    href: "https://www.scam.fr/donnees-personnelles/",
  },
  {
    title: "Gestion des cookies",
  },
  {
    href: "https://scam.fr",
    title: `© Scam ${new Date().getFullYear()}`,
  },
]
export const SOCIAL_MEDIAS = [
  {
    social: ContactWebsiteSocialMediaType.FACEBOOK,
    socialNetworkType: ContactWebsiteSocialMediaType.FACEBOOK,
    link: "",
    placeholder: "www.facebook.com/mapage/",
  },
  {
    social: ContactWebsiteSocialMediaType.LINKED_IN,
    socialNetworkType: ContactWebsiteSocialMediaType.LINKED_IN,
    link: "",
    placeholder: "www.linkedin.com/in/codelinkedin/",
  },
  {
    social: ContactWebsiteSocialMediaType.VIMEO,
    socialNetworkType: ContactWebsiteSocialMediaType.VIMEO,
    link: "",
    placeholder: "www.vimeo.com/codevimeo/",
  },
  {
    social: ContactWebsiteSocialMediaType.YOUTUBE,
    socialNetworkType: ContactWebsiteSocialMediaType.YOUTUBE,
    link: "",
    placeholder: "www.youtube.com/channel/codeyoutube/",
  },
  {
    social: ContactWebsiteSocialMediaType.INSTAGRAM,
    socialNetworkType: ContactWebsiteSocialMediaType.INSTAGRAM,
    link: "",
    placeholder: "www.instagram.com/moncompte/",
  },
  {
    social: ContactWebsiteSocialMediaType.TWITTER,
    socialNetworkType: ContactWebsiteSocialMediaType.TWITTER,
    link: "",
    placeholder: "twitter.com/moncompte/",
  },
]

export const NAVIGATION_PATHS_PROCESS_ON_LINE = [
  { index: 0, label: "Profil" },
  { index: 1, isChild: true },
  { index: 2, label: "Coordonnées" },
  { index: 3, isChild: true },
  { index: 4, isChild: true },
  { index: 5, label: "Infos bancaires" },
  { index: 6, isChild: true },
  { index: 7, label: "Signature" },
]

export const HOW_TO_REGISTER_ITEMS: {
  label: string
  value: string
}[] = [
  {
    label: "Vous avez une œuvre à déclarer à la Scam",
    value: "1",
  },
  {
    label:
      "Vous êtes co-auteur ou co-autrice d’une œuvre déjà déclarée à la Scam",
    value: "2",
  },
  {
    label:
      "Vous êtes journaliste travaillant ou ayant travaillé pour France TV, Radio France, AFP, Ina, LCP, Public Sénat",
    value: "3",
  },
]

export const EMPLOYERS_ITEMS: {
  label: string
  value: string
}[] = [
  {
    label: "Service public radio / télévision",
    value: "0",
  },
  {
    label: "AFP",
    value: "1",
  },
  {
    label: "LCP Assemblée Nationale",
    value: "2",
  },
  {
    label: "Public Sénat",
    value: "3",
  },
]

export const LAST_EMPLOYERS_ITEMS: {
  label: string
  value: string
}[] = [
  {
    label: "AFP",
    value: "1",
  },
  {
    label: "France 2",
    value: "2",
  },
  {
    label: "France 3",
    value: "3",
  },
  {
    label: "France 4",
    value: "4",
  },
  {
    label: "France 5",
    value: "5",
  },
  {
    label: "FRANCE TELEVISIONS",
    value: "6",
  },
  {
    label: "FTVI",
    value: "7",
  },
  {
    label: "LCP/AN",
    value: "8",
  },
  {
    label: "LCP/Public Sénat",
    value: "9",
  },
  {
    label: "ORTF",
    value: "10",
  },
  {
    label: "Radio France",
    value: "11",
  },
  {
    label: "RFO",
    value: "12",
  },
  {
    label: "TF1",
    value: "13",
  },
]

export const CONTRACTS_TYPES_ITEMS: {
  label: string
  value: string
}[] = [
  {
    label: "CDI",
    value: "1",
  },
  {
    label: "CDD occasionnel",
    value: "2",
  },
  {
    label: "CDD pigiste",
    value: "3",
  },
  {
    label: "CDI sur émission",
    value: "4",
  },
  {
    label: "Retraité",
    value: "5",
  },
]

export const PARCOURS_STEP = [
  "conditionspal",
  "provideditem",
  "reasonadhesion",
  "personalinformation",
  "addressinformation",
  "identityinformation",
  "socialnetworkinformation",
  "bankinformation",
  "signature",
  "summarizeinformation",
]
export const PAL_STATE = ["Created", "Updated", "Completed", "Deleted"]
export const LOCAL_STORAGE_USER_HAS_CONNECTED_SPACE = "USER_HAS_CONNECTED_SPACE"
export const LOCAL_STORAGE_USER = "USER"
export const LOCAL_STORAGE_EXPIRATION_MINUTES = 20
export const LOCAL_STORAGE_EXPIRATION_REDIRECT_DOCUSIGN_MINUTES = 5
export const LOCAL_STORAGE_USER_ID = "USER_ID"
export const LOCAL_STORAGE_USER_LAST_CONNECTION_DATE =
  "USER_LAST_CONNECTION_DATE"

export const REPERTORIESPROFILE = [
  {
    label: "Audiovisuel",
    value: "Audiovisual",
  },
  {
    label: "Accords journalistes ou presse écrite",
    value: "JournalistAgreement",
  },
  {
    label: "Sonore",
    value: "Sonorous",
  },
  {
    label: "Images Fixes",
    value: "StillImage",
  },
  {
    label: "Ecrit",
    value: "Written",
  },
]

export const REPERTORIES = [
  {
    label: "Audiovisuel",
    value: "Audiovisual",
    color: "var(--repertories-audiovisual)",
  },
  {
    label: "Sonore",
    value: "Radio",
    color: "var(--repertories-audiovisual)",
  },
  {
    label: "Sonore",
    value: "Sonorous",
    color: "var(--repertories-sonorous)",
  },
  {
    label: "Écrit",
    value: "Written",
    color: "var(--repertories-written)",
  },
  {
    label: "Images fixes",
    value: "StillImage",
    color: "var(--repertories-stillImage)",
  },
  {
    label: "Accords journalistes ou presse écrite",
    value: "Journalist",
    color: "var(--repertories-journalist)",
  },
  {
    label: "Accords journalistes ou presse écrite",
    value: "JournalistAgreement",
    color: "var(--repertories-journalist)",
  },
  {
    label: "Remboursement RS",
    value: "SpecificRefund",
    color: "var(--repertories-specificRefund)",
  },
]

export const STATUS_LABEL: StatusProps[] = [
  {
    value: WorkStatus.INTEGRATED_WORK,
    defaultLabel: "Référencée",
    order: 4,
    defaultColor: "green",
    defaultActions: [
      {
        label: "Consulter le détail",
        value: ActionsEnum.CHECKOUT_DETAILS,
        url: "",
      },
      {
        label: "Télécharger le PDF de la déclaration",
        value: ActionsEnum.DOWNLOAD_APPLICATION,
        url: "",
      },
    ],
    hasComplementActionWithCapability: true,
  },
  {
    value: WorkStatus.LIST_SIGNED_IN_PROGRESS_BY_DECLARER,
    defaultLabel: "En cours de signature",
    order: 2,
    defaultColor: "grey",
    defaultActions: [
      {
        label: "Consulter le détail",
        value: ActionsEnum.CHECKOUT_DETAILS,
        url: "",
      },
      {
        label: "Télécharger le PDF de la déclaration",
        value: ActionsEnum.DOWNLOAD_APPLICATION,
        url: "",
      },
    ],
  },
  {
    value: WorkStatus.ON_GOIN_WORK,
    defaultLabel: "Non finalisée",
    order: 1,
    defaultColor: "grey",
    defaultActions: [
      {
        label: "Reprendre la déclaration",
        value: ActionsEnum.EDIT,
        url: "EcDeclarationsRedirect.html",
      },
      {
        label: "Supprimer la déclaration",
        value: ActionsEnum.REMOVE,
        url: "EcDeclarationsRedirect.html",
      },
    ],
  },
  {
    value: WorkStatus.REFERENCED_WORK,
    defaultLabel: "Référencée",
    order: 4,
    defaultColor: "green",
    defaultActions: [
      {
        label: "Consulter le détail",
        value: ActionsEnum.CHECKOUT_DETAILS,
        url: "",
      },
      {
        label: "Télécharger le PDF de la déclaration",
        value: ActionsEnum.DOWNLOAD_APPLICATION,
        url: "",
      },
    ],
    hasComplementActionWithCapability: true,
  },
  {
    value: WorkStatus.REFUSAL_DELETED_WORK,
    defaultLabel: "Refusée",
    labels: [
      {
        scumStatuses: [ScamStatus.DELETED_WORK, ScamStatus.DUPLICATE_WORK],
        value: "Supprimée",
      },
    ],
    order: 4,
    defaultColor: "grey",
    colors: [
      {
        scumStatuses: [ScamStatus.DELETED_WORK, ScamStatus.DUPLICATE_WORK],
        value: "black",
      },
    ],
    defaultActions: [
      {
        label: "Consulter le détail",
        value: ActionsEnum.CHECKOUT_DETAILS,
        url: "",
      },
    ],
    actions: [
      {
        scumStatuses: [ScamStatus.DELETED_WORK],
        value: [
          {
            label: "Consulter le détail",
            value: ActionsEnum.CHECKOUT_DETAILS,
            url: "",
          },
          {
            label: "Télécharger le PDF de la déclaration",
            value: ActionsEnum.DOWNLOAD_APPLICATION,
            url: "",
          },
        ],
      },
    ],
    tooltips: [
      {
        scumStatuses: [ScamStatus.DUPLICATE_WORK],
        value: "Œuvre en doublon",
      },
      {
        scumStatuses: [ScamStatus.NON_COMPLIANT_FUNCTION],
        value: "Fonction contractuelle non-conforme",
      },
      {
        scumStatuses: [ScamStatus.NON_COMPLIANT_CONTRACT],
        value: "Contrat non-conforme",
      },
    ],
  },
  {
    value: WorkStatus.REFUSE_BY_AUTHOR,
    defaultLabel: "Refusée par co-auteur ou co-autrice",
    order: 3,
    defaultColor: "red",
    defaultActions: [
      {
        label: "Reprendre la déclaration",
        value: ActionsEnum.EDIT,
        url: "EcDeclarationsRedirect.html",
      },
      {
        label: "Consulter le détail",
        value: ActionsEnum.CHECKOUT_DETAILS,
        url: "",
      },
      {
        label: "Télécharger le PDF de la déclaration",
        value: ActionsEnum.DOWNLOAD_APPLICATION,
        url: "",
      },
      {
        label: "Supprimer la déclaration",
        value: ActionsEnum.REMOVE,
        url: "EcDeclarationsRedirect.html",
      },
    ],
  },
  {
    value: WorkStatus.SIGNED_IN_PROGRESS,
    defaultLabel: "En cours de signature",
    order: 2,
    defaultColor: "grey",
    defaultActions: [
      {
        label: "Consulter le détail",
        value: ActionsEnum.CHECKOUT_DETAILS,
        url: "",
      },
      {
        label: "Télécharger le PDF de la déclaration",
        value: ActionsEnum.DOWNLOAD_APPLICATION,
        url: "",
      },
    ],
  },
  {
    value: WorkStatus.VALIDATED_BY_AUTHOR,
    defaultLabel: "Validée par l'auteur ou l'autrice",
    order: 4,
    defaultColor: "black",
    defaultActions: [
      {
        label: "Consulter le détail",
        value: ActionsEnum.CHECKOUT_DETAILS,
        url: "",
      },
      {
        label: "Télécharger le PDF de la déclaration",
        value: ActionsEnum.DOWNLOAD_APPLICATION,
        url: "",
      },
    ],
    hasComplementActionWithCapability: true,
  },
  {
    value: WorkStatus.WORK_IN_PROGRESS,
    defaultLabel: "Référencée",
    order: 4,
    defaultColor: "orange",
    defaultActions: [
      {
        label: "Consulter le détail",
        value: ActionsEnum.CHECKOUT_DETAILS,
        url: "",
      },
      {
        label: "Télécharger le PDF de la déclaration",
        value: ActionsEnum.DOWNLOAD_APPLICATION,
        url: "",
      },
    ],
    tooltips: [
      {
        scumStatuses: [ScamStatus.MISSING_CONTRACT],
        value: "Contrat manquant",
      },
      {
        scumStatuses: [ScamStatus.MISSING_CO_AUTHOR_CONTRACT],
        value: "Contrat co-auteur ou co-autrice manquant",
      },
      {
        scumStatuses: [ScamStatus.MISSING_AUTHOR_CONTRACT],
        value: "Contrat auteur ou autrice manquant",
      },
    ],
    hasComplementActionWithCapability: true,
  },
]

export const STATUS_ACTIONS_FORMULAIRES: Array<ActionProps> = [
  {
    label: "Modifier le formulaire",
    value: ActionsEnum.MODIFICATION,
    url: "EcDeclarationsRedirect.html",
  },
  {
    label: "Modifier l’espace de suivi",
    value: ActionsEnum.MODIFICATION_BO_MONITORING,
    url: "EcDeclarationsRedirect.html",
  },
  {
    label: "Publier les modifications",
    value: ActionsEnum.DOWNLOAD_APPLICATION,
    url: "EcDeclarationsRedirect.html",
  },
]

export const STATUS_ACTIONS_COMPLEMENTARY: Array<ActionProps> = [
  {
    label: "Créer une liste d'œuvre",
    value: ActionsEnum.CREATE_LIST,
    url: "EcDeclarationsRedirect.html",
  },
  {
    label: "Signaler une nouvelle exploitation",
    value: ActionsEnum.CREATE_DOL,
    url: "EcDeclarationsRedirect.html",
  },
  {
    label: "Déclarer un renouvellement sur ce même exploitant",
    value: ActionsEnum.RENEW,
    url: "EcDeclarationsRedirect.html",
  },
]

export const STATUS_ACTIONS_CANDIDATURE: Array<ActionProps> = [
  {
    label: "Reprendre votre candidature",
    value: ActionsEnum.MODIFICATION,
    url: "EcDeclarationsRedirect.html",
  },
  {
    label: "Abandonner votre candidature",
    value: ActionsEnum.ABANDONE,
    url: "EcDeclarationsRedirect.html",
  },
  {
    label: "Supprimer votre candidature",
    value: ActionsEnum.REMOVE,
    url: "EcDeclarationsRedirect.html",
  },
  {
    label: "Consulter le détail de votre candidature",
    value: ActionsEnum.CHECKOUT_DETAILS,
    url: "EcDeclarationsRedirect.html",
  },
  {
    label: "Visualiser / Télécharger votre candidature",
    value: ActionsEnum.DOWNLOAD_APPLICATION,
    url: "EcDeclarationsRedirect.html",
  },
]

export const countryFrWithDom = [
  Country.FRA,
  Country.GUF,
  Country.MTQ,
  Country.GLP,
  Country.REU,
]

export const IBAN_TOOLTIP =
  "Votre IBAN est indiqué sur votre relevé d'identité bancaire (RIB) et dans votre chéquier"

export const GENDERCODELABELS = [
  {
    label: "Documentaire unitaire et grand reportage unitaire",
    value: "UNI",
  },
  {
    label: "Droit image ou « retransmission sportive »",
    value: "SPO",
  },
  {
    label: "Générique & Habillage",
    value: "GEN",
  },
  {
    label: "Nouvelle Technologie",
    value: "NTE",
  },
  {
    label: "Reportage",
    value: "REP",
  },
  {
    label: "Reportage d'Investigation",
    value: "INV",
  },
  {
    label: "Reportage Tour de France",
    value: "TDF",
  },
  {
    label: "Série",
    value: "SER",
  },
  {
    label: "Série documentaire",
    value: "SRD",
  },
  {
    label: "Traduction",
    value: "TRA",
  },
]

export const FLAGS = {
  CopyrightSimulator: "11262_simulateurdroit",
  UpdateProfile: "11257_modifprofil",
  Pbe: "22578_bourse",
  TunelContact: "25493_tunnelcontact",
}

export const MENUS = (
  isAnHeir: boolean,
  isTunelContact: boolean,
): Array<MenuModel> => {
  return [
    {
      label: "Accueil",
      hasSubmenu: false,
      path: "/",
      showForAllMembers: true,
      showIsAnHeir: true,
      showIsBe: true,
      showCurios: true,
      showOthersThanBe: true,
    },
    {
      label: "Vos œuvres",
      hasSubmenu: false,
      path: "/oeuvres",
      showForAllMembers: true,
      showIsAnHeir: false,
      showIsBe: true,
      showCurios: false,
      showOthersThanBe: true,
    },
    {
      label: "Détails de votre œuvre",
      hasSubmenu: false,
      path: "/oeuvre",
      showForAllMembers: true,
      showIsAnHeir: false,
      showIsBe: true,
      showOthersThanBe: true,
      showCurios: false,
      hiddenMenu: true,
    },
    {
      label: "Toutes vos œuvres déclarées",
      hasSubmenu: false,
      path: "/toutes-oeuvres",
      showForAllMembers: true,
      showIsAnHeir: false,
      showIsBe: true,
      showOthersThanBe: true,
      showCurios: false,
      hiddenMenu: true,
    },
    {
      label: "La vie de la Scam",
      hasSubmenu: true,
      showForAllMembers: true,
      showIsAnHeir: true,
      showIsBe: true,
      showOthersThanBe: true,
      showCurios: true,
      subMenus: [
        {
          label: "Nos missions",
          path: "https://www.scam.be/fr/la-scam",
          showForAllMembers: true,
          showIsAnHeir: true,
          showIsBe: true,
          showOthersThanBe: false,
          showCurios: false,
        },
        {
          label: "Nos missions",
          path: "https://www.scam.fr/lessentiel/nos-missions/",
          showForAllMembers: true,
          showIsAnHeir: true,
          showIsBe: false,
          showOthersThanBe: true,
          showCurios: true,
        },
        {
          label: "Les équipes de la Scam",
          path: "https://www.scam.fr/lessentiel/fonctionnement/organigramme/",
          showForAllMembers: true,
          showIsAnHeir: true,
          showIsBe: false,
          showOthersThanBe: true,
          showCurios: true,
        },
        {
          label: "La Scam en chiffres",
          path: "https://www.scam.fr/lessentiel/la-scam-en-chiffres/",
          showForAllMembers: true,
          showIsAnHeir: true,
          showIsBe: true,
          showOthersThanBe: true,
          showCurios: true,
        },
        {
          label: "L'action sociale de la Scam",
          path: "/content-page/L'action_sociale_de_la_Scam",
          showForAllMembers: false,
          showIsAnHeir: true,
          showIsBe: true,
          showOthersThanBe: true,
          showCurios: false,
        },
        {
          label: "Les instances de la Scam",
          path: "/instances-page",
          showForAllMembers: false,
          showIsAnHeir: true,
          showIsBe: true,
          showOthersThanBe: true,
          showCurios: false,
        },
        {
          label: "Le comité belge",
          path: "https://www.scam.be/fr/agir/le-comite-belge-de-la-scam",
          showForAllMembers: true,
          showIsAnHeir: true,
          showIsBe: true,
          showOthersThanBe: false,
          showCurios: false,
        },
      ],
    },
    {
      label: "Vos droits d’auteur",
      hasSubmenu: false,
      path: "/suivi-droit",
      showForAllMembers: false,
      showIsAnHeir: true,
      showIsBe: true,
      showOthersThanBe: true,
      showCurios: false,
    },
    ...(!isAnHeir
      ? [
          {
            label: "Les Prix, Bourses et Étoiles",
            path: "/pbe-suivi-candidature",
            hasSubmenu: false,
            showForAllMembers: true,
            showIsAnHeir: true,
            showIsBe: true,
            showOthersThanBe: true,
            showCurios: true,
          },
        ]
      : []),
    {
      label: "Vos documents",
      path: "/vos-documents",
      hasSubmenu: false,
      showForAllMembers: true,
      showIsAnHeir: true,
      showIsBe: true,
      showOthersThanBe: true,
      showCurios: false,
    },
    {
      label: "Les espaces Agnès Varda",
      path: "https://www.scam.fr/vous-creez-des-oeuvres/accedez-a-lespace-agnes-varda/",
      hasSubmenu: false,
      showForAllMembers: true,
      showIsAnHeir: true,
      showIsBe: true,
      showOthersThanBe: true,
      showCurios: false,
    },
    {
      label: "Aide et contact",
      hasSubmenu: true,
      showForAllMembers: true,
      showIsAnHeir: true,
      showIsBe: true,
      showOthersThanBe: true,
      showCurios: true,
      subMenus: [
        ...(!isTunelContact
          ? [
              {
                label: "Contacter la Scam",
                path: "https://www.scam.be/fr/contact",
                showForAllMembers: true,
                showIsAnHeir: true,
                showIsBe: true,
                showOthersThanBe: false,
                showCurios: false,
              },
              {
                label: "Contacter la Scam",
                path: "https://www.scam.fr/contactez-nous/",
                showForAllMembers: true,
                showIsAnHeir: true,
                showIsBe: false,
                showOthersThanBe: true,
                showCurios: true,
              },
            ]
          : []),
        {
          label: isTunelContact ? "FAQ & Contacts" : "Foire aux questions",
          path: "https://www.scam.be/fr/centre-de-ressources/faq",
          showForAllMembers: true,
          showIsAnHeir: true,
          showIsBe: true,
          showOthersThanBe: false,
          showCurios: false,
        },
        {
          label: isTunelContact ? "FAQ & Contacts" : "Foire aux questions",
          path: "/faq",
          showForAllMembers: true,
          showIsAnHeir: true,
          showIsBe: false,
          showOthersThanBe: true,
          showCurios: true,
        },
        {
          label: "Percevez vos droits",
          path: "https://www.scam.be/fr/remuneration/declarer-son-oeuvre-pour-toucher-ses-droits",
          showForAllMembers: true,
          showIsAnHeir: true,
          showIsBe: true,
          showOthersThanBe: false,
          showCurios: false,
        },
        {
          label: "Percevez vos droits",
          path: "https://www.scam.fr/vous-creez-des-oeuvres/declarez-vos-oeuvres-percevez-vos-droits/",
          showForAllMembers: true,
          showIsAnHeir: true,
          showIsBe: false,
          showOthersThanBe: true,
          showCurios: false,
        },
        {
          label: "Répertoires des œuvres",
          path: "https://www.scam.fr/lessentiel/repertoires-des-oeuvres/",
          showForAllMembers: true,
          showIsAnHeir: true,
          showIsBe: true,
          showOthersThanBe: true,
          showCurios: false,
        },
        {
          label: "Promouvoir vos œuvres",
          path: "/promote-works",
          showForAllMembers: false,
          showIsAnHeir: true,
          showIsBe: true,
          showOthersThanBe: true,
          showCurios: false,
        },
        {
          label: "FAQ TVA",
          path: "https://www.scam.be/images/FAQ_TVA_Scam_20200901.pdf",
          showForAllMembers: true,
          showIsAnHeir: true,
          showIsBe: true,
          showOthersThanBe: false,
          showCurios: false,
        },
        {
          label: "TVA",
          path: "/tva",
          showForAllMembers: true,
          showIsAnHeir: true,
          showIsBe: false,
          showOthersThanBe: true,
          showCurios: false,
        },
      ],
    },
    {
      label: "Vos démarches",
      path: "/vos-demarches",
      hasSubmenu: false,
      showForAllMembers: true,
      showIsAnHeir: false,
      showIsBe: true,
      showOthersThanBe: true,
      showCurios: true,
    },
    {
      label: "Actualités & Ressources",
      hasSubmenu: false,
      path: "https://www.scam.be/fr/actualites",
      showForAllMembers: true,
      showIsAnHeir: true,
      showIsBe: true,
      showOthersThanBe: false,
      showCurios: false,
    },
    {
      label: "Actualités & Ressources",
      hasSubmenu: false,
      path: "/actualites-ressources",
      showForAllMembers: true,
      showIsAnHeir: true,
      showIsBe: false,
      showOthersThanBe: true,
      showCurios: true,
    },

    {
      path: "/declarer-oeuvre",
      showForAllMembers: true,
      showIsAnHeir: false,
      showIsBe: true,
      showOthersThanBe: true,
      showCurios: false,
      hiddenMenu: true,
    },
    {
      path: "/revenus-impots",
      showForAllMembers: false,
      showIsAnHeir: true,
      showIsBe: false,
      showOthersThanBe: true,
      showCurios: false,
      hiddenMenu: true,
    },
    {
      path: "/revenus-urssaf",
      showForAllMembers: false,
      showIsAnHeir: true,
      showIsBe: false,
      showOthersThanBe: true,
      showCurios: false,
      hiddenMenu: true,
    },
    {
      path: "/releve-droit",
      showForAllMembers: false,
      showIsAnHeir: true,
      showIsBe: true,
      showOthersThanBe: true,
      showCurios: false,
      hiddenMenu: true,
    },
    {
      path: "/content-page/Planning_des_repartitions",
      showForAllMembers: false,
      showIsAnHeir: true,
      showIsBe: true,
      showOthersThanBe: true,
      showCurios: false,
      hiddenMenu: true,
    },
    {
      path: "/content-page/Planning_des_repartitions_Be",
      showForAllMembers: false,
      showIsAnHeir: true,
      showIsBe: true,
      showOthersThanBe: true,
      showCurios: false,
      hiddenMenu: true,
    },
    {
      path: "/content-page/Tarifs",
      showForAllMembers: false,
      showIsAnHeir: true,
      showIsBe: true,
      showOthersThanBe: true,
      showCurios: false,
      hiddenMenu: true,
    },
    {
      path: "/content-page/Tarifs_Be",
      showForAllMembers: false,
      showIsAnHeir: true,
      showIsBe: true,
      showOthersThanBe: true,
      showCurios: false,
      hiddenMenu: true,
    },
    {
      path: "/membership",
      showForAllMembers: true,
      showIsAnHeir: false,
      showIsBe: true,
      showOthersThanBe: true,
      showCurios: false,
      hiddenMenu: true,
    },

    {
      path: "/instances/conseils-administrations",
      showForAllMembers: false,
      showIsAnHeir: true,
      showIsBe: true,
      showOthersThanBe: true,
      showCurios: false,
      hiddenMenu: true,
    },
    {
      path: "/assemblees-generales/common-info",
      showForAllMembers: false,
      showIsAnHeir: true,
      showIsBe: true,
      showOthersThanBe: true,
      showCurios: false,
      hiddenMenu: true,
    },
    {
      path: "/assemblees-generales/social-rank",
      showForAllMembers: false,
      showIsAnHeir: true,
      showIsBe: true,
      showOthersThanBe: true,
      showCurios: false,
      hiddenMenu: true,
    },
    {
      path: "/assemblees-generales/notice-meetings-general",
      showForAllMembers: false,
      showIsAnHeir: true,
      showIsBe: true,
      showOthersThanBe: true,
      showCurios: false,
      hiddenMenu: true,
    },
    {
      path: "/assemblees-generales/voiting-meetings-general",
      showForAllMembers: false,
      showIsAnHeir: true,
      showIsBe: true,
      showOthersThanBe: true,
      showCurios: false,
      hiddenMenu: true,
    },
    {
      path: "/assemblees-generales/questions-ansvers",
      showForAllMembers: false,
      showIsAnHeir: true,
      showIsBe: true,
      showOthersThanBe: true,
      showCurios: false,
      hiddenMenu: true,
    },
    {
      path: "/assemblees-generales/results",
      showForAllMembers: false,
      showIsAnHeir: true,
      showIsBe: true,
      showOthersThanBe: true,
      showCurios: false,
      hiddenMenu: true,
    },
    {
      path: "https://www.scam.be/fr/agenda",
      showForAllMembers: true,
      showIsAnHeir: true,
      showIsBe: true,
      showOthersThanBe: false,
      showCurios: false,
      hiddenMenu: true,
    },
  ]
}

export enum DocumentPageOrientation {
  PORTRAIT = "portrait",
  LANDSCAPE = "landscape",
}

export interface DocumentPageFormatType {
  orientation: DocumentPageOrientation
  widthPoints: number
  heightPoints: number
  format: string
}

// export const DOCUMENT_PAGE_FORMAT = {
//   a4Portrait: {
//     orientation: DocumentPageOrientation.PORTRAIT,
//     widthPoints: (210 * 72) / 25.4, // 210mm to points
//     heightPoints: (297 * 72) / 25.4, // 297mm to points
//     format: "A4",
//   },
//   a4Landscape: {
//     orientation: DocumentPageOrientation.LANDSCAPE,
//     widthPoints: (297 * 72) / 25.4, // 297mm to points
//     heightPoints: (210 * 72) / 25.4, // 210mm to points
//     format: "A4",
//   },
// }

export const PBE_DOCUMENT_TYPE_LIST = [
  {
    value: PbeDocumentType.ATTACHMENT_AUTHOR_CV,
    label: "CV de l'auteur",
    description: ".pdf, 2 pages maximum, 5MO maximum",
    pageCount: 2,
    fileType: "application/pdf",
    maxSizeInBytes: 5 * 1024 * 1024,
  },
  {
    value: PbeDocumentType.ATTACHMENT_APPLICATION_FILE,
    label: "Votre dossier en PDF",
    description: ".pdf, 15 pages maximum, 5MO maximum",
    pageCount: 15,
    fileType: "application/pdf",
    maxSizeInBytes: 5 * 1024 * 1024,
  },
  {
    value: PbeDocumentType.ATTACHMENT_RIB,
    label: "RIB",
    description: ".pdf, 1 pages maximum, 5MO maximum",
    pageCount: 1,
    fileType: "application/pdf",
    maxSizeInBytes: 5 * 1024 * 1024,
  },
  {
    value: PbeDocumentType.ATTACHMENT_CONVENTION,
    label: "Convention",
    description: ".pdf",
    fileType: "application/pdf",
  },
  {
    value: PbeDocumentType.ATTACHMENT_TAX_DOCUMENT,
    label: "Document fiscal",
    description: ".pdf",
    fileType: "application/pdf",
  },
  {
    value: PbeDocumentType.ATTACHMENT_PORTRAIT,
    label: "Portrait",
    description:
      ".jpg, .jpeg, .tiff, .png, .heic, .heif, taille entre 1 mo et 5 mo",
    fileType: "application/image",
    filesExtentions: ["jpg", "jpeg", "tiff", "png", "heic", "heif"],
    maxSizeInBytes: 5 * 1024 * 1024,
    minSizeInBytes: 1 * 1024 * 1024,
  },
  {
    value: PbeDocumentType.ATTACHMENT_WITHHOLDING_TAX_EXEMPTION,
    label: "Dispense de précompte",
    description: ".pdf",
    fileType: "application/pdf",
  },
  {
    value: PbeDocumentType.ATTACHMENT_PHOTOGRAMME,
    label: "Photogramme",
    description: ".jpg, .jpeg, .tiff, .png, taille entre 1 mo et 5 mo",
    fileType: "application/image",
    filesExtentions: ["jpg", "jpeg", "tiff", "png"],
    maxSizeInBytes: 5 * 1024 * 1024,
    minSizeInBytes: 1 * 1024 * 1024,
  },
  {
    value: PbeDocumentType.ATTACHMENT_PROJECT_PRESENTATION,
    label: "Présentation du projet",
    description: ".pdf, 5 pages maximum, 5MO maximum",
    fileType: "application/pdf",
    pageCount: 5,
    maxSizeInBytes: 5 * 1024 * 1024,
  },
  {
    value: PbeDocumentType.ATTACHMENT_INTENT_NOTE,
    label: "Note d'intention",
    description: ".pdf, 2 pages maximum, 5MO maximum",
    fileType: "application/pdf",
    pageCount: 2,
    maxSizeInBytes: 5 * 1024 * 1024,
  },
  {
    value: PbeDocumentType.ATTACHMENT_SUPPLEMENTARY_TEXT,
    label: "Texte complémentaire",
    description: ".pdf, 5 pages maximum, 5MO maximum",
    fileType: "application/pdf",
    pageCount: 5,
    maxSizeInBytes: 5 * 1024 * 1024,
  },
  {
    value: PbeDocumentType.ATTACHMENT_DECLARATION_OF_HONOR,
    label: "Votre déclaration sur l'honneur",
    description: ".pdf, 1 pages maximum, 5 Mo maximum",
    subtitle: "Signé par l'ensemble des co-auteurs ou des co-autrices",
    pageCount: 1,
    fileType: "application/pdf",
    tooltipText:
      "Les bénéficiaires de la bourse de Repérages ou du Tutorat, qui peuvent avoir obtenu d’autres aides, doivent déclarer les éventuels soutiens reçus pour ce projet depuis celui de la Scam",
  },
]
